"use client";

import Link from "next/link";
import Image from "next/image";

import LogoImg from "../assets/img/LightBlack.png";

import { FaBars, FaFacebook, FaInstagram } from "react-icons/fa";
import { useThemeContext } from "./theme-provider";
import { useMobileMenu } from "@lib/context/mobile-menu-context";
import MobileMenu from "@modules/mobile-menu/templates";
import { usePathname } from "next/navigation";
import classNames from "classnames";

const collapseItems = [
	{ label: "Hem", link: "/" },
	{ label: "Fordon i lager", link: "/fordon" },
	{ label: "Sälj ditt fordon", link: "/salj-ditt-fordon" },
	{ label: "Kontakt", link: "/kontakt" },
	{ label: "Om oss", link: "/om-oss" },
];

interface HeaderResponsiveProps {
	links: { link: string; label: string }[];
}

export const Logo = () => {
	return (
		<div className="bg-white rounded-full p-[7px]">
			<Link href="/">
				<Image width={150} src={LogoImg} alt="VD Motor" priority blurDataURL={LogoImg.blurDataURL} />
			</Link>
		</div>
	);
};

const randomInteger = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

const HeaderResponsive = () => {
	// const user = nhost.auth.getUser();
	const theme = useThemeContext();
	const { toggle } = useMobileMenu();
	const pathname = usePathname();

	return (
		<header className="sticky top-0 w-full z-40 shadow-xl bg-black font-heading">
			<div className="navbar content-container px-4 xl:px-0">
				<div className="navbar-start gap-4">
					<label tabIndex={0} onClick={() => toggle()} className="btn btn-ghost lg:hidden">
						<FaBars />
					</label>
					<Logo />
				</div>
				<div className="navbar-center hidden lg:flex">
					<ul className="menu menu-horizontal px-1 gap-2">
						{collapseItems.map((item) => (
							<li key={item.link}>
								<Link
									className={classNames("px-6 py-4 uppercase font-semibold", {
										"bg-gray-400 bg-opacity-20": pathname === item.link,
									})}
									href={item.link}
								>
									{item.label}
								</Link>
							</li>
						))}
					</ul>
				</div>
				<div className="navbar-end gap-4">
					<Link aria-label="Gå till Facebook sida" href="https://facebook.com/vdmotor.se" target="_blank">
						<FaFacebook size={26} />
					</Link>
					<Link aria-label="Gå till Instagram sida" href="https://instagram.com/vdmotorab" target="_blank">
						<FaInstagram size={26} />
					</Link>
					{/* <a className="btn btn-primary uppercase">Kontakta oss</a> */}
				</div>
			</div>
			<MobileMenu />
		</header>
	);
};

export default HeaderResponsive;
